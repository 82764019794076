import { BToast } from "bootstrap-vue";

/**
 * Service to handle message
 */
const MessageService = {
  getMessage(data) {
    return Array.isArray(data) ? data[0].message : data.message;
  },
  displayMessage(data, options = {}) {
    const msg = this.getMessage(data);
    const bootStrapToaster = new BToast();

    const params = {
      title: "Message",
      toaster: "b-toaster-bottom-right",
      solid: true,
      variant: "success",
      appendToast: false,
      autoHideDelay: 5000
    };

    const newOptions = { ...params, ...options };
    bootStrapToaster.$bvToast.toast(msg, newOptions);
  }
};

export default MessageService;
