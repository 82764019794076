<template>
  <v-data-table
    :headers="headers"
    :items="tags.data"
    sort-by="calories"
    class="elevation-1"
    :loading="loading"
    loading-text="Loading... Please wait"
    :footer-props="{
      'items-per-page-options': [20, 30, 40, 50]
    }"
    :items-per-page="20"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn @click="downloadCsv" color="danger" class="me-2 mb-2">
          {{ $t("CATEGORIES.EXPORT") }}
        </v-btn>

        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              {{ $t("CATEGORIES.NEW_ITEM") }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.name"
                      label="Category name"
                      :rules="[isRequired]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-select
                      v-model="editedItem.tagType"
                      :items="tagTypes.data"
                      item-text="label"
                      return-object
                      :rules="[isRequired]"
                      label="Category type"
                      required
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-select
                      v-model="editedItem.company"
                      :items="companies"
                      :rules="[isRequired]"
                      label="Company name"
                      item-text="name"
                      return-object
                      required
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                {{ $t("CATEGORIES.CANCEL") }}
              </v-btn>
              <v-btn color="blue darken-1" text @click="save">
                {{ $t("CATEGORIES.SAVE") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >{{ $t("CATEGORIES.DELETE_ITEM") }}
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >{{ $t("CATEGORIES.CANCEL") }}
              </v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >{{ $t("CATEGORIES.OK") }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:item.tagType="{ item }">
      {{ item.tagType.label }}
    </template>

    <template v-slot:item.company="{ item }">
      {{ item.company.name }}
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>

    <template v-slot:no-data> </template>
  </v-data-table>
</template>

<script>
import MessageService from "@/common/message.service";
import {
  getTags,
  getTagTypes,
  updateTagV2,
  deleteTagV2,
  createTagV2
} from "@/api/categories";
import { getCompaniesV2 } from "@/api/companies/getters";
import { exportProgramsTags } from "@/api/programs/getters";
import axios from "axios";
import i18n from "@/common/plugins/vue-i18n";

import { mapGetters } from "vuex";

export default {
  name: "ListingCategory",
  props: {
    title: {
      type: String,
      default: "List"
    }
  },
  data() {
    return {
      loading: false,
      tags: [],
      tagTypes: [],
      languages: [],
      companies: [],
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        id: "",
        name: "",
        company: {},
        tagType: {}
      },
      defaultItem: {
        id: "",
        name: "",
        company: {},
        tagType: {}
      },
      defaultCompany: {}
    };
  },
  mounted() {
    this.load();
  },
  computed: {
    ...mapGetters(["currentUser"]),
    headers() {
      return [
        {
          text: "Name",
          sortable: true,
          sortDirection: "desc",
          value: "name"
        },
        {
          text: "Type",
          sortable: true,
          sortDirection: "desc",
          value: "tagType"
        },
        {
          text: "Company",
          sortable: true,
          sortDirection: "desc",
          value: "company.name"
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false
        }
      ];
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },
  methods: {
    async load() {
      this.loading = true;
      this.tags = await getTags(axios);
      this.tagTypes = await getTagTypes(axios);
      const companies = await getCompaniesV2(axios);
      this.defaultCompany = companies.data.find(
        el => el.externalId === this.currentUser.company.externalId
      );
      this.editedItem.company = this.defaultCompany;
      this.companies = companies.data;
      this.loading = false;
    },
    isRequired(v) {
      return !!v || "Item is required";
    },
    editItem(item) {
      this.editedIndex = this.tags.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.tags.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      this.loading = true;
      this.tags.data.splice(this.editedIndex, 1);
      try {
        await deleteTagV2(axios, this.editedItem.id);
        this.closeDelete();
        await this.load();
        MessageService.displayMessage({
          message: i18n.t("CATEGORIES.DELETED")
        });
      } catch (e) {
        this.closeDelete();
        this.loading = false;
        MessageService.displayMessage(
          {
            message: i18n.t("CATEGORIES.ERR_DELETED")
          },
          { variant: "danger" }
        );
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem, company: this.defaultCompany };
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem, company: this.defaultCompany };
        this.editedIndex = -1;
      });
    },
    async save() {
      if (this.editedIndex > -1) {
        try {
          await updateTagV2(
            axios,
            this.editedItem.company.id,
            this.editedItem.id,
            this.editedItem.tagType.id,
            this.editedItem
          );

          this.close();
          await this.load();
          MessageService.displayMessage({
            message: i18n.t("CATEGORIES.UPDATED")
          });
        } catch (e) {
          MessageService.displayMessage(
            {
              message: i18n.t("CATEGORIES.ERR_UPDATED")
            },
            { variant: "danger" }
          );
        }
      } else {
        try {
          await createTagV2(
            axios,
            this.editedItem.company.id,
            this.editedItem.id,
            this.editedItem.tagType.id,
            this.editedItem
          );
          this.close();
          await this.load();
          MessageService.displayMessage({
            message: i18n.t("CATEGORIES.CREATED")
          });
        } catch (e) {
          MessageService.displayMessage(
            {
              message: i18n.t("CATEGORIES.ERR_CREATED")
            },
            { variant: "danger" }
          );
        }
      }
    },
    async downloadCsv() {
      try {
        const csvContent = await exportProgramsTags(axios);
        const blob = new Blob([csvContent], {
          type: "text/csv;charset=utf-8;"
        });
        const blobURL = URL.createObjectURL(blob);

        const hiddenElement = document.createElement("a");
        hiddenElement.href = blobURL;
        hiddenElement.target = "_blank";

        const today = new Date().toISOString().slice(0, 10);

        hiddenElement.download = today + "_programs_tags.csv";
        hiddenElement.click();
      } catch (err) {
        console.error(err);
      }
    }
  }
};
</script>
