<template>
  <b-container class="kt-container" fluid>
    <b-row>
      <b-col xs="12" v-if="!isAdmin">
        <h2 class="text-left pl-5">Forbidden</h2>
      </b-col>

      <b-col xs="12" v-if="isAdmin">
        <ListingCategory :title="$t('CATEGORIES.TITLE')" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import ListingCategory from "@/components/ListingCategory";
import { mapGetters } from "vuex";
import i18n from "@/common/plugins/vue-i18n";

export default {
  name: "categories-languages",
  components: {
    ListingCategory
  },
  computed: {
    ...mapGetters({
      isAdmin: "isAdmin",
      loading: "entity/loading"
    })
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: i18n.t("MENU.CONFIGURATION.CATEGORIES") }
    ]);
  }
};
</script>
